import React, { useEffect, useState } from 'react'

import { CssBaseline } from '@material-ui/core'
import { withRedux } from './src/setup/withRedux'
import { withTheme } from './src/setup/withTheme'
import { withApollo } from './src/setup/withApollo'
import { withGoogleTag } from './src/setup/withGoogleTag'
import { initI18n } from './src/use/i18n/useI18n'
import { useLayoutStore } from './src/use/layout/useLayout'
import { PageCompose } from './src/compose/layout/PageCompose'
import { SEO } from './src/components/seo/Seo'
import { Splash } from './src/components/layout/Splash'

export function GatsbyTopLayout({
  children
}) {
  //useEffect(() => {
    initI18n()
  // [])


  return withGoogleTag(withRedux(withTheme(withApollo(
    <>
      <CssBaseline />
      <PageCompose>
        {children}
      </PageCompose>
    </>)
  )))
}