import styled, { css } from 'styled-components';
import { Drawer, IconButton } from 'components/core';
import { blackColor, drawerWidth, grayColor, whiteColor } from 'components/theme/constants';
import shadows from 'components/theme/shadows';
import { useMediaQuery } from '@material-ui/core';
import images from 'components/theme/images';


const LABEL = {
  openDrawer: 'open drawer'
}

export const HeaderDrawerStyled = styled(Drawer).attrs(({ theme }) => {
  const matches = useMediaQuery(theme.breakpoints.up('sm')) 
  return matches
  ? { variant: 'permanent', anchor: 'left', open: true }
  : { variant: 'temporary', anchor: 'right' }
})`
  ${({ theme }) => css`
    right: 0;
    left: auto;
    border-left: 1px solid ${grayColor[5]};
    width: ${drawerWidth}px;
    ${theme.breakpoints.up('sm')} {
      width: ${drawerWidth * 0.8}px;
      right: auto;
      left: 0;
    }
  `}
`
export const BackgroundStyled = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background: url(${images.mainBackground});
  background-size: cover;
  background-position: center center;
  &:after {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    content: '""';
    display: block;
    background: ${blackColor};
    opacity: .8;
  }
`
export const DrawerStyled = styled(Drawer)`
  opacity: 0.95;
`
export const DrawerDarkWrapper = styled.div`
  color: ${whiteColor};
  background-color: ${grayColor[9]};
  box-shadow: ${shadows.drawer};
  min-height: 6rem;
  ${({ theme }) => css`
    padding: ${theme.spacing(1.5)}px ${theme.spacing(3)}px;
      ${theme.breakpoints.up('sm')} {
        min-height: 3rem;
      }
  `}
`
export const SidebarWrapperStyled =  styled.div`
  position: relative;
  height: calc(100vh - 10rem);
  overflow: auto;
  width: ${drawerWidth -19}px;
  z-index: 4;
  overflow-scrolling: touch;
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: ${drawerWidth * 0.8 - 19}px;
    }
  `}
`
export const LogoStyled = styled.img.attrs(props => ({
  src: images.logo
}))`
  width: 2.5rem;
  margin-right: .5rem;
`
export const DrawerTitleStyled = styled.div`
  color: ${whiteColor};
`

export const LogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;
  border-bottom: 1px solid ${grayColor[0]};
  &:after {
    content: '""';
    position: absolute;
    bottom: 0;

    height: 1px;
    right: 15px;
    width: calc(100% - 30px);
    background-color: rgba(${grayColor[6]}, 0.3);
  }
`
export const ContentWrapperStyled = styled.div`
  margin: 1.25rem 0.625rem;
  margin-top: 5rem;
`

export const HeaderCloseWrapperStyled = styled(IconButton).attrs(props => ({
  color: 'inherit',
  'aria-label': LABEL.openDrawer
}))`
  position: absolute;
  right: 0.5rem;
  top: 0px;
  z-index: 2;
  color: ${grayColor[0]};
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `}
`



