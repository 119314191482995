import React, { FC} from 'react'
import { Alert } from 'components/message/Alert'
import useI18n from 'use/i18n/useI18n'
import { useModalAlert } from 'use/modal/useModal'


export interface IPopupAlert {

}
export const PopupAlertCompose: FC = () => {
  const { isOpen, data = {}, close } = useModalAlert()
  const { t } = useI18n()
  
  return (
  <>{ isOpen &&
    <Alert
      openAlert={isOpen}
      text={t(data.errorMessage)}
      severity={data.severity || 'error'}
      handleClose={close}
    />}
  </>
  )
}