import fetch from './effect'

const timeout = 4000 // 4 seconds timeout

async function post(endpoint: string, data?: any) {
  return fetch({
    method: 'post',
    url: endpoint,
    timeout,
    data
  })
}

async function get(endpoint: string, data?: any) {
  return fetch({
    method: 'get',
    url: endpoint,
    timeout,
    data
  })
}

export default function useFetch() {
  return {
    get,
    post,
  }
}
