import avatar from 'assets/images/default-avatar.png'
import logo from 'assets/images/logo.png'
import mainBackground from 'assets/images/index.jpg'
import CollectionModel from 'model/Collection.model'

export default {
  mainBackground,
  heroIndex: CollectionModel.getImage(28),
  avatar,
  logo
}