exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-buscar-tsx": () => import("./../../../src/pages/dashboard/buscar.tsx" /* webpackChunkName: "component---src-pages-dashboard-buscar-tsx" */),
  "component---src-pages-dashboard-cambios-buscar-tsx": () => import("./../../../src/pages/dashboard/cambios/buscar.tsx" /* webpackChunkName: "component---src-pages-dashboard-cambios-buscar-tsx" */),
  "component---src-pages-dashboard-cambios-tsx": () => import("./../../../src/pages/dashboard/cambios.tsx" /* webpackChunkName: "component---src-pages-dashboard-cambios-tsx" */),
  "component---src-pages-dashboard-colecciones-nueva-tsx": () => import("./../../../src/pages/dashboard/colecciones/nueva.tsx" /* webpackChunkName: "component---src-pages-dashboard-colecciones-nueva-tsx" */),
  "component---src-pages-dashboard-colecciones-tsx": () => import("./../../../src/pages/dashboard/colecciones.tsx" /* webpackChunkName: "component---src-pages-dashboard-colecciones-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-perfil-tsx": () => import("./../../../src/pages/dashboard/perfil.tsx" /* webpackChunkName: "component---src-pages-dashboard-perfil-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-olvido-tsx": () => import("./../../../src/pages/olvido.tsx" /* webpackChunkName: "component---src-pages-olvido-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */),
  "component---src-pages-reset-[selector]-[token]-tsx": () => import("./../../../src/pages/reset/[selector]/[token].tsx" /* webpackChunkName: "component---src-pages-reset-[selector]-[token]-tsx" */)
}

