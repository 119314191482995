import React, { FC, memo } from 'react'

import {
  DrawerLinksStyled,
  IconStyled,
  DrawerLinkStyled,
  ListItemStyled,
  ListItemTextStyled,
  DrawerSeparator,
  BigListItemStyled,
} from './styled'
import { IChildren, IMenuLink, IMenuProps, IMenuSep } from 'utils/interface'


const DrawerLinksMemo: FC<IMenuProps & IChildren> = ({
  index,
  links = [],
  onChange,
  children,
}) => {
 
  let firstSep = false;
  return (
    <DrawerLinksStyled>       
      {links.map((ob, i) => {
        const sep = ob as IMenuSep
        const link = ob as IMenuLink
        const Element = firstSep ? ListItemStyled : BigListItemStyled
        if (sep && sep.sep) {
          firstSep = true;
          return <DrawerSeparator key={i}/>
        } else {
          return (
            <li key={link.text}>
              <DrawerLinkWrapper {...link} >
                <Element
                  selected={index === i}
                  onClick={e => onChange(i)}>
                  <IconStyled icon={link.icon} />
                  <ListItemTextStyled
                    primary={link.text}
                  />
                </Element>
              </DrawerLinkWrapper>
            </li>
          )
        }})}
      { children }
    </DrawerLinksStyled>
  )
}

export const DrawerLinks = memo(DrawerLinksMemo)


const DrawerLinkWrapper:FC<IMenuLink> = ({
  href,
  onClick,
  children,
}) => (
  href
    ? <DrawerLinkStyled to={href}>{children}</DrawerLinkStyled>
    : <div onClick={onClick}>{children}</div>
)

