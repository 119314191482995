import React, { FC, memo } from 'react'
import { IHeaderType } from 'components/header/Header'
import { Avatar } from 'components/core'
import { CustomLink } from 'components/button/CustomLink'

const HeaderImgMemo: FC<IHeaderType> = ({
  value,
  linkTo
}) => {
  return (
    <CustomLink to={linkTo} ><Avatar src={value} /></CustomLink>
  )
}

export const HeaderImg = memo(HeaderImgMemo)
