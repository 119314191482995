interface IPayloadObject {
  payload: any;
}


export const setChange = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    change: payload,
  };
};
export const setExchanges = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    exchanges: payload,
  };
};