interface IPayloadBoolean {
  payload: boolean;
}

interface IPayloadObject {
  payload: any;
}

export const setUser = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    user: payload,
  };
};
export const setToken = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    token: payload,
  };
};
export const loginUser = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    user: payload,
  };
};
export const logoutUser = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    user: payload,
  };
};