import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { save, load } from 'redux-localstorage-simple'

import {appReducer } from './appReducer'
//import errorToastMiddleware from 'modules/message/middleware/errorToastMiddleware';
//import loaderMiddleware from 'modules/loader/middleware/loaderMiddleware';
//import filterMiddleware from 'modules/filter/middleware/filterMiddleware';
// import createSagaMiddleware from 'redux-saga'
import 'regenerator-runtime/runtime'
// import { rootSaga } from './rootSaga'
import { configureStore, MiddlewareArray } from '@reduxjs/toolkit'
import { initAuth } from '../use/auth/useAuth'
import { isBrowser } from 'utils/helpers';
import useStorage from 'use/storage/useStorage';
import { storageKey } from 'setup/constants';

const loadedState = isBrowser() && load({ namespace: storageKey })
const defaultInitialState = { layout: { firstLoad: true } }
export default () => {
  const logger = createLogger()
  const { clearItem } = useStorage()
  const resetStore = () => {
    clearItem(storageKey)
  }
  // const sagaMiddleware = createSagaMiddleware();
  //const middleware = [environment.isDevelopment ? reduxFreeze : null, thunk, routerMiddleware(history), errorToastMiddleware()].filter(Boolean);
  //const middleware = [thunk, logger, sagaMiddleware, errorToastMiddleware(), loaderMiddleware(), filterMiddleware() ]

  const store = configureStore({
    reducer: (state, action) => {
      if (action.type === 'auth/loginUser'
        || action.type === 'auth/logoutUser') {
        resetStore()
        return appReducer(defaultInitialState, action)
      }
      return appReducer(state, action)
    },
    // middleware: new MiddlewareArray().concat(thunk, logger),

    middleware: new MiddlewareArray().concat(thunk, logger
      ,save({
        namespace: storageKey,
        ignoreStates: ['layout']
      })),
    preloadedState: loadedState,
    // middleware: new MiddlewareArray().concat(thunk, logger, sagaMiddleware),
  })
  /*
  const store = createStore(
    rootReducer(history),
    initialState,
    //composeWithDevTools(applyMiddleware(...middleware))
    applyMiddleware(...middleware)
  );
    */
 // store.dispatch(AuthAction.load())
 // store.dispatch(I18nAction.init())
  initAuth(store.dispatch)

  // sagaMiddleware.run(rootSaga)

  // store.subscribe(() => console.log(store.getState()));

  // const saga = sagaMiddleware.run(rootSaga);
  return {
    store,
    // rootSaga: saga
  }
};
