import React, { FC, memo } from 'react'
import { IHeaderType } from 'components/header/Header'
import { Icon } from 'components/styled'
import { CustomLink } from 'components/button/CustomLink'

const HeaderLinkIconMemo: FC<IHeaderType> = ({
  value,
  size,
  linkTo,
  className,
}) => {
  return (
    <CustomLink className={className} to={linkTo} ><Icon icon={value} size={size}/></CustomLink>
  )
}

export const HeaderLinkIcon = memo(HeaderLinkIconMemo)
