import React, { FC } from 'react'
import { Alert } from 'components/message/Alert'
import useI18n from 'use/i18n/useI18n'
import { PrimaryButton, PrimaryEmptyButton } from 'components/common/common.styled'
import { IConfirmModel } from 'model/Confirm.model'
import { useModalConfirm } from 'use/modal/useModal'

const labelIds = {
  accept: 'popup.confirm.accept',
  cancel: 'popup.confirm.cancel'
}

export const PopupConfirmCompose: FC = () => {
  const { isOpen, close, data }: {
    isOpen: boolean,
    close: () => void,
    data: IConfirmModel
  } = useModalConfirm()
  const { tm } = useI18n()
  const defaultLabels = tm(labelIds)
  const { 
    accept,
    cancel,
    text,
    severity,
    duration = null,
    labels = defaultLabels,
   } = data || {}

  return (
    <>{ isOpen &&
      <Alert
        openAlert={isOpen}
        autoHideDuration={duration}
        anchor={{ vertical: 'bottom', horizontal: 'center' }}
        buttons={[
          { label: labels.cancel, onClick: cancel, item: PrimaryEmptyButton },
          { label: labels.accept, onClick: accept, item: PrimaryButton },
        ]}
        text={text}
        severity={severity}
        handleClose={close}
      />}
    </>
  )
}