/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(base, baseApi, baseAuth) {
  return {
    route: {
      //baseRoute: '/acabaramos', // Fixes issue with Github Pages
    },
    base,
    api: {
      auth: baseAuth,
      changes: `${baseApi}Cambios`,
      collections: `${baseApi}Coleccion`,
      userCollections: `${baseApi}Coleuser`,
      userElements: `${baseApi}Elemuser`,
      profile: `${baseApi}Userinfo`,
      queryUsers: `${base}queryuserinfo.php`
    },
    path: {
      uploads: `${base}uploads/`,
      img: `${base}img/`
    },
    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  };
}
