
import environment from 'setup/environment'
import { IAuthModel } from 'model/Auth.model'
import useFetch from 'use/fetch/useFetch'
import { ILoginProps, IRecoverProps, IRegisterProps, IResetHiddenProps, IResetProps } from 'utils/interface'


export {
  checkFn,
  loginFn,
  logoutFn,
  registerFn,
  recoverPasswordFn,
  resetPasswordFn
}


async function authFn (action: string, payload: any): Promise<IAuthModel> {
  const { post } = useFetch()
  const response = await post(`${environment.api.auth}/${action}`, payload)
  if (response.errorMessage) {
    return Promise.reject(response.errorMessage)
  }
  return Promise.resolve(response)
}
async function resetPasswordFn(payload: IResetProps & IResetHiddenProps) {
  return authFn('resetPassword', payload)
}
async function recoverPasswordFn(payload: IRecoverProps) {
  return authFn('forgotPassword', payload)
}
async function loginFn(payload: ILoginProps): Promise<IAuthModel> {
  return authFn('login', { ...{ remember: '1' }, ...payload})
}
async function registerFn(payload: IRegisterProps): Promise<IAuthModel> {
  return authFn('newuser', payload)
}
async function logoutFn(): Promise<IAuthModel> {
  return authFn('logout', {})
}
async function checkFn(): Promise<IAuthModel> {
  return authFn('check', {})
}