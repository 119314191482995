import { createSlice } from '@reduxjs/toolkit'
import * as set from './set'
import * as get from './get'

const { actions, reducer } = createSlice({
  name: 'layout',
  initialState: {
    firstLoad: false,
    spinner: false,
    alerts: false,
    pageTitle: ''
  },
  reducers: set
})

export {
  actions,
  get,
}
export default reducer
