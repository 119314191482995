export interface IFilterModel {
  name: string;
  input: any;
  label: string;
  options?: IFilterOption[];
  query?: (value: any, input: any) => boolean;
  server?: boolean;
  hideFilter?: boolean;
  disabled?: boolean;
}

export interface IFilterOption {
  title: string;
  value: string;  // if empty: !canBeDeleted
}
export interface IFilterIndexes {
  [key: string]: number[]
}
export type IFilterOptionMap = {
  [key: string]: IFilterOption;
};
export interface IFilterModelMap {
  [key: string]: IFilterModel
}
export type TFilterMapper<T> = (item: T, model: IFilterModel) => boolean
const accentMap = {
  'á': 'a',
  'é': 'e',
  'í': 'i',
  'ó': 'o',
  'ú': 'u'
}
export default class FilterModel {

  // TODO traducir
  static EMPTY = [{ title: '(sin selección)', value: ''}]

  // static filter(arr: Array<any>, filter: IFilterModel) {
  //   return arr.filter((item: Object) => {
  //     const value = objectPath.get(item, filter.name)
  //     const input = filter.input
  //     return input
  //      ? (filter.query
  //         ? filter.query(value, input)
  //         : value === input)
  //      : true
  //   })
  // }
/*
  static setActive(arr: IFilterModel[], filterName: String) {
    return arr.map(data => Object.assign({}, data, { active: data.name === filterName }))
  }
*/

  // static filterBy(arr: Array<any>, filters: IFilterModel[]):
  //   Array<any> {
  //   return filters
  //     .reduce((tot, filter) => FilterModel.filter(tot, filter), arr)
  // }
  // static updateInput(arr: IFilterModel[], filterName: String, input: String) {
  //   debugger
  //   return (arr || []).map(data => (Object.assign({}, data,
  //     data.name === filterName ? { input } : {})))
  // }
  static update(arr: IFilterModel[], filterName: String, filter: Partial<IFilterModel>) {
    return (arr || []).map(data => (Object.assign({}, data,
      data.name === filterName ? filter : null)))
  }
  static flatName(name: string): string {
    return name.toLowerCase().split('').map(a => accentMap[a] || a).join('')
  }
  static getValues = (filter: IFilterModel[]): any => {
    const values = {}
    filter.forEach(ob => {
      values[ob.name] = ob.input
    });
    return values
  }
  static filterList = <T>(
    list: T[],
    model: IFilterModel[],
    filterMapper: TFilterMapper<T>
  ) => {
    if (!model || !list) return list
    const inputs = model.filter(filter => !!filter.input)
    
    return !inputs.length ? list : list.filter(
      item => inputs
        .map(filter => filterMapper(item, filter))
        .every(Boolean)
    )
  }

  // static updateFilterOptions(list: unknown[], filters: IFilterModel[]): IFilterModel[] {
  //   const totalOptions: { [key: string]: IFilterOption[] } = {}
  //   const totalIndexes: { [key: string]: IFilterIndexes } = {}

  //   list.forEach((item, i) => {
  //     filters.forEach(filter => {
  //       const options: IFilterOption[] = totalOptions[filter.name] || []
  //       const value = `${item[filter.key]}`
  //       if (!totalIndexes[filter.name]) {
  //         totalIndexes[filter.name] = {}
  //       }
  //       const indexes = totalIndexes[filter.name][value] || []
  //       options.push({ title: value, value })
  //       indexes.push(i)
        
  //       totalOptions[filter.name] = options
  //       totalIndexes[filter.name][value] = indexes
  //     })
  //   })

  //   return filters.map(filter => ({
  //     ...filter, ...{ options: totalOptions[filter.name], indexes: totalIndexes[filter.name] }
  //   }))
  // }
}
