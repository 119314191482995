import React from 'react'
import { ReactElement } from 'react'

import { InMemoryCache, ApolloLink, from, HttpLink, ApolloProvider, ApolloClient, Reference, concat } from '@apollo/client'
import { useApolloClient } from "@apollo/client";
import fetch from 'cross-fetch'
import { onError } from '@apollo/client/link/error'
import { navigate } from 'gatsby';
import { replaceHistoryState } from 'utils/helpers';
import env from './environment';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHORIZED':
          // console.log('WITH APOLLO UNAUTHORIZED', window.location.href, err);
          replaceHistoryState({
            err: err.message,
            code: err.extensions.code
          }, window.location.href)
          // navigate(window.location.href, {
          //   state: {
          //     err: err.message,
          //     code: err.extensions.code
          //   },
          //   replace: true
          // })
      }
    }
  }
  // console.log({graphQLErrors})
  //   graphQLErrors.map(({ message, originalError, locations, path }) => {
  //     console.log({ originalError })
  //     console.log(
  //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //     )
  //   });
  if (networkError) console.log(`[Network error]: ${networkError}`, operation);
});

const consoleLink = new ApolloLink((operation, forward) => {
  // console.log(`starting request for ${operation.operationName}`);
  return forward(operation).map((data) => {
    // console.log(`ending request for ${operation.operationName}`);
    return data;
  })
})

const customFetch = (uri, options) => {
  try {
    return fetch(uri, options)
  } catch (e) {
    console.log(`ERROR == [customFetch]`, e);
  }
};

const additiveLink = from([
  consoleLink,
  errorLink,
  new HttpLink({
    uri: env.GATSBY_API_URL,
    fetch: customFetch,
    fetchOptions: {
      mode: 'no-cors',
    }
  })
])

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    console.log('headers', headers);
    return { headers }
  });

  return forward(operation);
})

const mergePagesPolicy = {
  // Don't cache separate results based on
  // any of this field's arguments.
  //keyArgs: false,
  // Concatenate the incoming list items with
  // the existing list items.
  merge(existing = { data:[], page:0, total:0 }, incoming) {
    const incomingData = incoming.data || []
    const pageLength = incoming.page ? existing.data.length / incoming.page : 0
    const offset = pageLength * incoming.page
    const result = {
      ...incoming,
      ...{
        data: mergeBlock(existing.data, incomingData, offset, incomingData.length)
      }
    }
    return result
  },
  read(existing, a) {
    if (a?.variables?.page?.noMerge) {
      return undefined
    }
    return existing
  }
}

const mergeBlock = (existing: any[], incoming: any[], offset: number, limit: number) => {
  const merged = existing ? existing.slice(0) : [];
  // Insert the incoming elements in the right places, according to args.
  const end = offset + Math.min(limit, incoming.length)
  for (let i = offset; i < end; ++i) {
    merged[i] = incoming[i - offset]
  }
  return merged
}

const readBlock = (existing: any[], offset: number, limit: number) => {
  // If we read the field before any data has been written to the
  // cache, this function will return undefined, which correctly
  // indicates that the field is missing.
  const page = existing && existing.slice(
    offset,
    offset + limit,
  );
  // If we ask for a page outside the bounds of the existing array,
  // page.length will be 0, and we should return undefined instead of
  // the empty array.
  if (page && page.length > 0) {
    return page;
  }
}
const mergeBlockSimple = (existing: any[], incoming: any[]) => (
  [...existing, ...incoming]
)


const mergeBlockByValue = (existing: any, incoming: any[], value: string) => (
  {
    ...existing,
    [value]: [ ...existing[value], ...incoming]
  }
)

function mergeArrayByField(
  field: string,
  existing: Reference[],
  incoming: Reference[],
  readField: (field: string, item: Reference) => string
) {
  const merged = Object.assign({ ...existing })
  // console.log('mergeArrayByField merged', merged, incoming);
  

  
  incoming.forEach((item) => {
    console.log('MERGE ARRAY', field, item);
    merged[readField(field, item)] = item
  })
  return merged
}
/*
const mergePagesPolicy = {
  // Don't cache separate results based on
  // any of this field's arguments.
  keyArgs: false,
  // Concatenate the incoming list items with
  // the existing list items.
  merge(existing, incoming, { args }:{ args: IVariables }) {
    const { page } = args
    return mergeBlock(existing && existing.data, incoming.data, page.page, page.per_page)
  }
}


typePolicies: 
      Query: {
        fields: {
          // userCollectionsPage: mergePagesPolicy,
          // userExchangesPage: mergePagesPolicy,
          readExchanges: {
            read(userExchangesPage, { readField }) {
              const data = readField('userExchangesPage')?.data ?? [];
              // console.log(data);
              
               return data; 
            },
          },
          userExchangesPage2: {
            merge(existing = {}, incoming, { readField, args }) {
              const { filter } = args
              const { state } = filter
              const result = incoming.data
              return {
                ...existing,
                page: incoming.page,
                total: incoming.total,
                [state]: mergeArrayByField('id', existing[state], result, (field: string, item: Reference) => {
                  return readField(field, item['change'])
                })
              }
            },
            read(existing, { args }) {
              if (!existing) return existing
              const { filter } = args
              const { state } = filter
              // console.log('READ', existing, state);
              return {
                data: Object.values(existing[state] || {}),
                page: existing.page,
                total: existing.total
              }
            }
          }
        }
      },
      UserExchangePagination2: {
        fields: {
          data: {
            merge: (existing, incoming, args) => {
              console.log('UE MERGE', existing, incoming, args);
              
              return [ ...existing || [], ...incoming ]
            },
            read: (existing, args) => {
              console.log('UE READ', existing, args);
              return existing
            }
          }
        }
      }
*/
export function createApolloClient(initialState = {}) {
  let ssrMode = typeof window == "undefined"
  let cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          collectionsPage: mergePagesPolicy,
          searchCollectionsPage: mergePagesPolicy,
          userCollectionExPage: mergePagesPolicy,
          userCollectionsPage: mergePagesPolicy,
          userExchangesPage: mergePagesPolicy,
        }
      }
    }
    }).restore(initialState)

  return new ApolloClient({
    connectToDevTools: !ssrMode,
    credentials: 'same-origin',
    ssrMode,
    // link: concat(authMiddleware, additiveLink),
    link: additiveLink,
    cache,
  })
}
function createIsomorphLink() {
  return new HttpLink({
    uri: env.GATSBY_API_URL,
    fetch: fetch,
  })
}


export function withApollo (element: ReactElement) {
  const client = createApolloClient()
  return (
    <ApolloProvider client={client}>{element}</ApolloProvider>
  )
}
export function clearCache() {
  const client = useApolloClient()
  client.clearStore()
}