import { drawers, routes } from 'setup/constants'
import icons from 'components/theme/icons'

import useAuth from 'use/auth/useAuth'
import useI18n from 'use/i18n/useI18n'
import { useModalAuth, useModalStore } from 'use/modal/useModal'
import { navigate } from 'gatsby'
import { IMenuLink, IMenuSep } from 'utils/interface'

const SEPARATOR = 'sep'
export const menuItemMapper = (t: { (): string; (arg0: string): any; }) => (item: string, onClick: any):IMenuLink | IMenuSep => (
  item === SEPARATOR
  ? { sep: true }
  : { ...{
        icon: icons[item],
        text: t(`menu.${item}`)
      },
      ...(onClick ? { onClick } : { href: routes[item] })
    }
)

const drawerlinks = {
  menu: ['publicSearch', 'allCollections', 'community', 'blog', 'help'],
  status: ['userDashboard', 'userCollections', 'userChanges'], //, 'userSearch'],
  logout: ['logout'],
  login: ['login']
}
const useMenu = (id: string, route: string) => {
  const { t } = useI18n()
  const { open: openAuth } = useModalAuth()
  const { logout, user } = useAuth()
  const { open, isOpen, close } = useModalStore(`drawer.${id}`)

  const mapClicks = {
    logout: () => {
      logout({
        onSuccess: () => {
          navigate(routes.home)
        } 
      })
    },
    login: () => {
      openAuth()
    }
  }

  let items = (drawerlinks[id] || [])
  if (id === drawers.menu) {
    const authLinks = user ? drawerlinks.logout : drawerlinks.login

    items = drawerlinks.status
      .concat([SEPARATOR])
      .concat(items)
      .concat([SEPARATOR])
      .concat(authLinks)
      .concat([SEPARATOR])
  }
  const links = mapLinks(items, t, mapClicks)
  const drawer = {
    title: t('common.brand'),
    index: links.findIndex(indexMapper(route)),
    links
  }

  return {
    open: isOpen,
    toggle: () => {
      isOpen ? close() : open()
    },
    drawer
  }
}

const mapLinks = (items: string[], t, mapClicks: any): (IMenuLink | IMenuSep)[] => 
  items.map((key: string) =>
    menuItemMapper(t)(key, mapClicks[key]))

export default useMenu

const indexMapper = realRoute => {
  const route = {
    [routes.userChangeSearch]: routes.userChanges,
    [routes.userCollectionsJoin]: routes.userCollections
  }[realRoute] || realRoute
  return ob => ob.href === route
}