import styled, { css } from 'styled-components';
import { grayColor, infoColor } from 'components/theme/constants';
import { Button, Snackbar } from 'components/core';
import { Icon } from 'components/styled';
import icons from 'components/theme/icons';
import shadows from 'components/theme/shadows';

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5rem;
`
export const ButtonStyled = styled(Button).attrs(props => ({
  variant: 'outlined'
}))`
`

export const ListWrapper = styled.ul`
  list-style: none;
  padding: 0 0 0 0rem;
`
export const MessageWrapper = styled.div`
  width: 100%;
  padding: 1.5rem;
  font-size: 0.7rem;
  margin-top: 0%;
  margin-bottom: 0%;

  @keyframes fade_out {
    0% {
      opacity: 1;
      margin-top: 0%;
      margin-bottom: 0%;
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
      -moz-transform: scaleY(1);
    }
    100% {
      opacity: 0;
      margin-top: -25%;
      margin-bottom: -25%;
      transform: scaleY(0);
      -webkit-transform: scaleY(0);
      -moz-transform: scaleY(0);
    }
  }
  display: ${({ closed }) => closed ? 'none': 'inline-block'};
  &[hidden] {
    animation: fade_out 0.8s;
  }
`;
export const MessageCardWrapper = styled(MessageWrapper)`
  box-shadow: ${shadows.card};
  border: 1px solid ${infoColor[3]};
  background: ${infoColor[5]};
  color: ${grayColor[1]};
  padding: 1rem;
`
export const MessageInfoWrapper = styled(MessageWrapper)`
  background: ${infoColor[5]};
  color: ${grayColor[1]};
`



export const IconStyled = styled(Icon)`
  display: block;
  margin: 0 auto 1rem;
  height: 1.5rem;
  font-size: 1.5rem;
`
export const InfoIcon = styled(IconStyled).attrs(props => ({
    icon: icons.help
  }))`
  color: ${infoColor[2]};
`
export const SnackbarStyled = styled(Snackbar)`
  .MuiAlert-root a {
    font-style: italic;
    font-weight: bold;
    text-decoration: underline;
    color: inherit;
  }
`