import { combineReducers } from 'redux'

import authReducer from 'use/auth/store'
import exchangeReducer from 'use/exchange/store'
import filterReducer from 'use/filter/store'
import layoutReducer from 'use/layout/store'
import modalReducer from 'use/modal/store'

export const appReducer = combineReducers({
  auth: authReducer,
  exchange: exchangeReducer,
  filter: filterReducer,
  layout: layoutReducer,
  modal: modalReducer,
})