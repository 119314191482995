import styled from 'styled-components';

export const ListWrapperStyled = styled.ul`
 list-style: none;
 padding: 0;
 -webkit-overflow-scrolling: touch;

 & li:active {
  opacity: 0.3;
 }
`
export const ListContainerHorizontalStyled = styled(ListWrapperStyled)`
  margin: 0;
  display: grid;
  overflow: scroll;
  grid-auto-flow: column;
  grid-auto-columns: 7.2rem;
  grid-gap: 0.5rem;
`;

export const ListContainerVerticalStyled = styled(ListWrapperStyled)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: auto;
  & li {
    width: 100%;
    height: auto;
    padding: 0.5rem;
  }
`;