interface IPayloadObject {
  payload: any;
}

export const setFirstLoad = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    firstLoad: payload.firstLoad
  };
};

export const setSpinner = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    spinner: payload.spinner
  };
};

export const setPageTitle = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    pageTitle: payload.pageTitle
  };
};