import React, { FC } from 'react'
import { IChildren } from 'utils/interface'
import {
  ListContainerHorizontalStyled,
  ListContainerVerticalStyled
} from 'components/list/styled'
import { CustomLink } from 'components/button/CustomLink'


export interface IListProps extends IChildren {
  variant: 'horizontal' | 'vertical';
  linkTo?: (any) => string;
  onClick?: (e: any) => void;
  list?: [any];
  className?: string;
  item: React.FC<any>;
  skeleton?: React.ReactNode;
}

export const List: FC<IListProps> = ({ 
  variant,
  linkTo,
  onClick,
  list = [],
  item: Item,
  skeleton,
  className,
  children
}) => {

  const ListWrapper = {
    horizontal: ListContainerHorizontalStyled,
    vertical: ListContainerVerticalStyled
  }[variant]
  return (
    <ListWrapper className={className}>    
      {list.map((item: any, i: number) =>
        <li key={i}>
          {linkTo && <CustomLink out={linkTo(item)} ><Item {...item} /></CustomLink>}
          {onClick && <div onClick={() => { onClick(item) }}><Item  {...item} /></div>}
          {!linkTo && !onClick && <Item  {...item} />}
        </li>
      )}
      {list.length === 0 && skeleton && [1,2,3,4].map((item: any, i: number) => 
        <li key={i}>
          { skeleton }
        </li>
      )}
      <li>{children}</li>
    </ListWrapper>
  )
}