import React, { FC, memo } from 'react';
import { IChildren, } from 'utils/interface';
import { IconDefinition } from 'components/theme/icons';
import { Button } from 'components/core';
import { IconRightStyled, IconStyled } from './styled';

export interface IButtonProps extends IChildren {
  icon?: IconDefinition;
  iconRight?: IconDefinition;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  item?: React.FC<any>;
}


const RawButtonWithIconMemo: FC<IButtonProps> = ({
  icon,
  iconRight,
  children,
  disabled,
  onClick,
  className,
  item,
}) => {
  const Wrapper = item || Button

  return (
    <Wrapper size='small' className={className} disabled={disabled} onClick={onClick}>
      { icon && <IconStyled icon={icon} />}{ children }{ iconRight && <IconRightStyled icon={iconRight} />}
    </Wrapper>
  );
};

export const RawButtonWithIcon = memo(RawButtonWithIconMemo)