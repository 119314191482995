import styled from 'styled-components'
import { Link } from 'gatsby'
import { Icon } from 'components/styled'
import { Theme } from '@material-ui/core';
import { grayColor, infoColor } from 'components/theme/constants';
import { Switch } from 'components/core';
import { buttonBackground, colorBackground, colorText } from 'components/common/styled';

export const ButtonClearStyled = styled.button<{ theme: Theme }>`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: ${({ theme }) => theme.typography.fontSize};
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed,
  }
`;
export const ButtonIconWrapperStyled = styled(ButtonClearStyled)`
  padding: 1rem;
  color: inherit;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  & > * {
    margin: 1rem 0.5rem;
  }
`
export const ButtonListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  & > * {
    margin: 1rem 0.5rem;
  }
  margin: 1rem 0;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid ${grayColor[0]};
  list-style: none;
`
export const ButtonListItemStyled = styled.li.attrs(props => ({
  color: props.color,
  bg: props.bg
}))`
  margin: 0;
  width: 100%;
  border-bottom: 1px solid ${grayColor[0]};
  display: inline-block;
  & button, & p {
    ${props => {
      if (props.color) {
        return colorText(props)
      }
      if (props.bg) {
        return buttonBackground(props)
      }
    }}
    width: 100%;
    align-items: center;
    display: flex;
    line-height: 1.75;
    font-size: 0.875rem;
    padding: 6px 8px;
    justify-content: flex-start;
    & svg.svg-inline--fa {
      width: 2rem;
    }
    & em  {
      text-align: left;
      font-style: normal;
      width: 60%;
    }
    & small {
      font-size: 0.6rem;
      line-height: 0.6rem;
      color: ${grayColor[0]};
      position: relative;
      display: block;
    }
    & .MuiSwitch-root {
      margin-top: -1rem;
      margin-bottom: -1rem;
    }
  }

`
export const IconStyled = styled (Icon)`
  margin-right: 0.5rem;
`
export const IconRightStyled = styled (Icon)`
  margin-left: 0.5rem;
`
export const AStyled = styled.a`
  color: inherit;
  text-decoration: none;
`
export const LinkStyled = styled(Link)`
  color: inherit;
  text-decoration: none;
`
export const SwitchStyled = styled(Switch)`
  position: absolute;
  right: 0;
`
