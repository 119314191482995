import styled, { css } from 'styled-components';
import { ListItem, ListItemText } from 'components/core';
import { grayColor, hexToRgb, whiteColor } from 'components/theme/constants';

import { Icon } from 'components/styled';
import { CustomLink } from 'components/button/CustomLink';

export const DrawerLinksStyled = styled.ul`
  margin-top: 20px;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  list-style: none;
  position: unset;
`
export const DrawerLinkStyled = styled(CustomLink).attrs(props =>({
  activeClassName: "active"
}))`
  position: relative;
  display: block;
  color: ${whiteColor};
  &:hover,&:focus,&:visited {
    color: ${whiteColor};
  }
  &.active {
    background: rgba(256,256,256,0.3);
    border-radius: .5rem;
  }
`
export const DrawerSeparator = styled.hr`
  background-color: ${grayColor[1]};
  border: 0;
  height: 1px;
`

export const ListItemStyled = styled(ListItem).attrs(props =>({
  button: true
}))`
  width: auto;
  transition: all 300ms linear;
  border-radius: 3px;
  position: relative;
  display: flex;
  padding: 0.5rem 1rem;
  background-color: transparent;
  .MuiListItemText-root {
    color: ${grayColor[0]};
  }

  & svg.svg-inline--fa {
    width: 1rem;
  }
`
export const BigListItemStyled = styled(ListItemStyled)`
  .MuiListItemText-root {
    color: ${whiteColor};
    font-size: 1.1rem;
  }
`

export const ListItemTextStyled = styled(ListItemText).attrs(props =>({
  disableTypography: true
}))`
  margin: 0;
  line-height: 1.3rem;
  font-size: 0.9rem;
  margin-left: 1rem;
  display: inline-block;
  color: ${whiteColor};
`

export const IconStyled = styled(Icon)`
  font-size: 1.1rem;
  line-height: 1.4rem;
  float: left;
  color: rgba(${hexToRgb(whiteColor)}, 0.8)
`