import { isBrowser } from 'utils/helpers'
import useI18n from '../i18n/useI18n'
import { useDispatch, useSelector } from 'react-redux'
import { actions as layoutActions, get as layoutGet } from './store'

const { isFirstLoad, isSpinner, getTitle } = layoutGet
const { setFirstLoad, setSpinner, setPageTitle } = layoutActions

const modal = (open) => {
  // const root = document.documentElement; // '0' to assign the first (and only `HTML` tag)
  // root.classList[open ? 'add' : 'remove']('noscroll')
}
const useLayout = (route = '') => {
  const { t } = useI18n()
  let title = ''
  let seo
  if (route) {
    const page = route.replace('/', '')
    title = t(`pages.${page || 'home'}.title`)
    seo = {
      title
    }
  }
  return {
    initScroll,
    openModal: () => modal(true),
    closeModal: () => modal(false),
    page: {
      seo,
      loading: false, //useSelector(getLoading),
    },
    title
  }
}

const initScroll = () => {
  if (isBrowser()) {
    window.scrollTo(0, 0)
    return true
  }
}

export const useLayoutStore = function() {
  const dispatch = useDispatch()
  const updateFirstLoad = (firstLoad) => {
    dispatch(setFirstLoad({ firstLoad }))
  }
  const updateSpinner = (spinner) => {
    dispatch(setSpinner({ spinner }))
  }
  const updatePageTitle = (pageTitle) => {
    dispatch(setPageTitle({ pageTitle }))
  }

  return {
    isFirstLoad: useSelector(isFirstLoad),
    updateFirstLoad,
    isSpinner: useSelector(isSpinner),
    openSpinner: () => updateSpinner(true),
    closeSpinner: () => updateSpinner(false),
    pageTitle: useSelector(getTitle),
    updatePageTitle,
  }
}

export default useLayout