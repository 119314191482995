import { ICollectionModel } from 'model/Collection.model';
import { ISearchCollectionModel } from 'model/SearchCollection.model';
import { IUserCollectionModel } from 'model/UserCollection.model';
import { IUserExchangeModel } from 'model/UserExchange.model';
import { isBrowser } from 'utils/helpers';
import env from './environment';

// const oldPath = `${isBrowser() ? window.location.protocol : 'https:'}//acabaramos.com`;
//  const oldPath = 'https://alianzas.online/dev';
  const oldPath = env.base

export const routes = {
  allCollections: `${oldPath}colecciones`,
  blog: `${oldPath}blog`,
  change: `${oldPath}comunidad/cambio?c=:id`,
  collectionName: `${oldPath}nombrecoleccion/:id`,
  collectionDetail: `${oldPath}colecciones/:id`,
  cookiePolicy: '/cookies',
  community: `${oldPath}comunidad`,
  error: '/error',
  footerEmail: 'mailto:dudas@acabaramos.com',
  footerTwitter: 'https://twitter.com/acabaramos_com',
  footerFacebook: 'https://www.facebook.com/acaba.ramos.52',
  help: `${oldPath}comunidad/ayuda`,
  home: '/',
  imgSave: `${oldPath}img.php`,
  imgShare: filename => `${oldPath}uploads/${filename}`,
  public: page => `${oldPath}comunidad/${page}`,
  publicSearch: `${oldPath}comunidad/buscar`,
  publicUser: `${oldPath}comunidad/user?u=:id`,
  resetPassword: '/reset',
  userDashboard: '/dashboard',
  userChanges: '/dashboard/cambios',
  userChangeDetail: '/dashboard/cambios/:id',
  userChangeSearch: '/dashboard/cambios/buscar',
  userChangesQuery: '/dashboard/cambios/?:query',
  userCollections: '/dashboard/colecciones',
  userCollectionDetail: '/dashboard/colecciones/:id',
  userCollectionEdit: `${oldPath}dashboard/colecciones/:id/edit`,
  userCollectionShare: `${oldPath}dashboard/colecciones/:id/share`,
  userCollectionsJoin: '/dashboard/colecciones/nueva',
  userProfile: `${oldPath}dashboard/perfil`,
  userSearch: '${oldPath}dashboard/buscar',
};

export const layouts = {
  public: 'public',
  private: 'private',
};

export const headerTypes = {
  avatar: 'avatar',
  brand: 'brand',
  menu: 'menu',
  username: 'username',
};

export const modals = {
  alert: 'alert',
  alertWelcome: 'alertWelcome',
  auth: 'auth',
  change: 'change',
  collection: 'collection',
  collectionEdit: 'collectionEdit',
  collectionShare: 'collectionShare',
  confirm: 'confirm',
  result: 'result',
  top: 'top',
  user: 'user',
};

export const filters = {
  change: 'change',
  collection: 'collection',
  newCollection: 'newCollection',
  newChange: 'newChange',
};


export const drawers = {
  menu: 'menu',
  status: 'status',
};

export const flags = {
  firstTimeUser: 'first',
  popupChangeIntro: 'popup.change.intro',
  popupCookies: 'popup.cookies',
  popupAlertWelcome: 'popup.alert.welcome',
};

export const storageKey = 'acabaramos_data'
export const nav = {
  publicAlert: (url: string) => `${oldPath}${url}`,
  publicChanges: (item: IUserExchangeModel) => routes.change.replace(':id', `${item.change.id}`),
  publicCollection: (item: Partial<ICollectionModel>) => routes.collectionName.replace(':id', `${formatNameToUrl(item.name)}`),
  publicSearchCollection: (item: ISearchCollectionModel) =>
    routes.collectionName.replace(':id', `${formatNameToUrl(item?.collection?.name)}`),
  userChangesQueryCode: (code: number) => routes.userChangesQuery.replace(':query', `code=${code}`),
  userChangesQueryUser: (id: number) => routes.userChangesQuery.replace(':query', `user=${id}`),
    userCollectionRoute: (item: IUserCollectionModel) =>
    routes.userCollectionDetail.replace(':id', `${item.collection.code}`),
  userCollectionEditRoute: (code: number) =>
    routes.userCollectionEdit.replace(':id', `${code}`),
  userCollectionShareRoute: (code: number) =>
    routes.userCollectionShare.replace(':id', `${code}`),
};
const formatNameToUrl = (name: string) => {
  const url = (name || '')
    .trim()
    .split(/ - | – /g)
    .join('-')
    .replace(new RegExp(/[\.\(\)\,ºª´!¡:“”]/g), '')
    .replace(new RegExp(/[ /]/g), '-')
    .replace('á', 'a')
    .replace('é', 'e')
    .replace('í', 'i')
    .replace('ó', 'o')
    .replace('ú', 'u')
    .toLowerCase();
  return url;
};

