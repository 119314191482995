import cookie from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { IAuthModel } from 'model/Auth.model'
import {
  checkFn,
  loginFn,
  logoutFn,
  registerFn,
  recoverPasswordFn,
  resetPasswordFn
} from './authEffect'

import { authActions, authGet } from './store'
import { isBrowser } from 'utils/helpers'
import useStorage from 'use/storage/useStorage'
import { IResetHiddenProps } from 'utils/interface'

const { getUser } = authGet
const { setUser, loginUser, logoutUser } = authActions
const { getItem, setItem, clearItem } = useStorage()

export const initAuth = (dispatch) => {
  const user = loadUser()
  if (user && user.accessGranted) {
    updateUser(dispatch, user)
  } else {
    dispatch(setUser(null))
  }
}

interface IOnResult {
  onSuccess?: any,
  onError?: any
}

export default function useAuth() {
  const dispatch = useDispatch()
  return {
    user: (useSelector(getUser) || {}).userId,
    // user: 21,
    check,
    login,
    register, 
    recoverPassword,
    resetPassword,
    loadResetData,
    saveResetData,
    logout: (result?: IOnResult) => {
      const { onSuccess, onError } = result || {}
      logout({dispatch, onSuccess, onError })
    },
  }
}
const USER_STORAGE_KEY = 'stored_user'
const USER_STORAGE_OLD_KEY = 'data'
const USER_STORAGE_RESET_DATA_KEY = 'reset_data'

const loadUser = isBrowser()
  ? () => getItem(USER_STORAGE_KEY)
  : () => ({})
const storeUser = isBrowser()
  ? (user: IAuthModel) => {
    if (user) {
      const oldUserData = {login: user}
      setItem(USER_STORAGE_KEY, user)
      setItem(USER_STORAGE_OLD_KEY, oldUserData)
    } else {
      clearItem(USER_STORAGE_KEY)
      clearItem(USER_STORAGE_OLD_KEY)
    }
  }
  : () => {}

const updateUser = (dispatch, userDetail: IAuthModel, action?: Function) => {
  if (userDetail) {
    if (!userDetail.accessGranted) {
      throw new Error()
    }
    dispatch((action || setUser)({
      accessGranted: !!userDetail.accessGranted,
      action: userDetail.action && `${userDetail.action}`,
      data: userDetail.data,
      email: userDetail.email && `${userDetail.email}`,
      errorMessage: userDetail.errorMessage,
      success: userDetail.success,
      userId: userDetail.userId && parseInt(userDetail.userId),
      userName: userDetail.userName && `${userDetail.userName}`
    }))
    storeUser(userDetail)
  } else {
    dispatch((action || setUser)(null))
    storeUser(null)
  }
}

async function fn (asyncFn, onSuccess = null, onError = null) {
  try {
    const detail: IAuthModel = await asyncFn()
    if (onSuccess) {
      onSuccess(detail)
    }
    return detail
  } catch (error) {
    if (onError) {
      onError(error)
    }
    return error
  }
}
async function login ({
  dispatch,
  email,
  password,
  onSuccess,
  onError
}) {
  fn(async () => {
    const userDetail = await loginFn({ email, password })
          //cookie.get('PHPSESSID', { token, user }, { path: '/' })
    // const cookies = cookie.get()
    // console.log('COOKIES', value)
    // console.log('sessionId', cookies.sessionID)
    
    updateUser(dispatch, userDetail, loginUser)
    return userDetail
  }, onSuccess, onError)
}

async function recoverPassword ({
  dispatch,
  email,
  onSuccess,
  onError
}) {
  return fn(async () => {
    const userDetail = await recoverPasswordFn({ email })
    updateUser(dispatch, null, logoutUser)
    return userDetail
  }, onSuccess, onError)
}

async function resetPassword ({
  dispatch,
  password,
  onSuccess,
  onError
}) {
  return fn(async () => {
    const selector = ''
    const token = ''
    const userDetail = await resetPasswordFn({
      password,
      selector,
      token
    })
    updateUser(dispatch, null, logoutUser)
    return userDetail
  }, onSuccess, onError)
}

async function logout ({
  dispatch,
  onSuccess = null,
  onError = null
}) {
  fn(async () => {
    await logoutFn()
    updateUser(dispatch, null, logoutUser)
    return true
  }, onSuccess, onError)
}

async function register ({
  dispatch,
  email,
  password,
  username,
  onSuccess,
  onError
}) {
  fn(async () => {
    const userDetail = await registerFn({ email, password, username })
    updateUser(dispatch, userDetail, loginUser)
    return userDetail
  }, onSuccess, onError)
}

async function check ({
  dispatch,
  onSuccess = null,
  onError = null
}) {
  fn(async () => {
    const userDetail = await checkFn()
    try {
      updateUser(dispatch, userDetail)
    } catch(e) {
      storeUser(userDetail)
    }
    return userDetail
  }, onSuccess, onError)
}

function saveResetData (data: IResetHiddenProps) {
  setItem(USER_STORAGE_RESET_DATA_KEY, data)
}
function loadResetData (): IResetHiddenProps {
  const data = getItem(USER_STORAGE_RESET_DATA_KEY)
  clearItem(USER_STORAGE_RESET_DATA_KEY)
  return data
}