import styled, { css } from 'styled-components';

import { blackColor, dangerColor, facebookColor, grayColor, hexToRgb, infoColor, roseColor, successColor, twitterColor, warningColor, whatsappColor, whiteColor } from 'components/theme/constants';
import shadows from 'components/theme/shadows';
import { HeroColor } from 'components/hero/Hero';

const black = hexToRgb(blackColor)
const white = hexToRgb(whiteColor)
const getColor = (color:string | HeroColor, theme) => ({
  primary: theme?.palette.primary.main,
  secondary: theme?.palette.secondary.main,
  success: successColor[0],
  warning: warningColor[0],
  info: infoColor[0],
  danger: dangerColor[0],
  rose: roseColor[0],
  gray: grayColor[7],
  facebook: facebookColor,
  twitter: twitterColor,
  whatsapp: whatsappColor,
}[color])

function getGradient(color, theme) {
  const colors = {
    primary: [ theme.palette.primary.main, theme.palette.primary.dark ],
    secondary: [ theme.palette.secondary.main, theme.palette.secondary.dark ],
    success: [ successColor[1], successColor[2] ],
    warning: [ warningColor[1], warningColor[2] ],
    info: [ infoColor[1], infoColor[2] ],
    danger: [ dangerColor[1], dangerColor[2] ],
    rose: [ roseColor[1], roseColor[2] ],
    gray: [ grayColor[7], grayColor[8] ],
  }[color]
  return colors
}
export function outlinedBackground ({ color, theme }) {
  if (color) {
    const hex = getColor(color, theme)
    const rgb = hexToRgb(hex)
    return css`
      color: ${whiteColor};
      background: transparent;
      box-shadow: ${shadows.box(rgb)};
      border: 1px solid ${hex};

      &:hover {
        background-color: ${hex};
      }
    `
  }
  return ''
}
export function whiteBackground ({ color, theme }) {
  if (color) {
    const hex = hexToRgb(getColor(color, theme))
    return css`
      color: ${grayColor[1]};
      background: ${whiteColor};
      box-shadow: ${shadows.box(hex)};
    `
  }
  return ''
}
export function colorText ({ color, theme }) {
  if (color) {
    const hex = getColor(color, theme)
    return css`
      color: ${hex};
    `
  }
  return ''
}
export function colorBackground ({ color, theme }) {
  if (color) {
    const hex = getColor(color, theme)
    const rgb = hexToRgb(hex)
    return css`
      background: ${hex};
      box-shadow: ${shadows.box(rgb)};
    `
  }
  return ''
}
export function gradientBackground ({ color, theme }) {
  if (color) {
    const [ ini, end ] = getGradient(color, theme)
    return css`
      background: linear-gradient(60deg, ${ini}, ${end});
      box-shadow: ${shadows.box(hexToRgb(ini))};
      & h1 small {
        color: rgba(${white}, 0.8);
      }
      color: ${whiteColor};
    `
  }
  return ''
}
export function buttonBackground ({ color, theme = undefined }) {
  css`
    &:hover, &:focus {
      color: ${whiteColor};
      background-color: ${grayColor[0]};
      box-shadow: ${shadows.buttonFocus};
    }`
  if (color) {
    const hex = getColor(color, theme)
    const rgb = hexToRgb(hex)
    return css`
      content: '${color}';
      box-shadow: ${shadows.button(rgb)};
      background-color: ${hex};
      color: ${whiteColor};
      &:hover,&:focus,&.MuiButton-root:hover.Mui-disabled {
        background-color: rgb(${rgb});
        box-shadow: ${shadows.buttonFocus(rgb)};
      }
    `
  }
  return ''
}
