import React, { FC } from 'react'
import { Trans } from 'react-i18next'

interface IName {
  text: string;
  defaults?: string;
  values?: any;
}

export const TransText: FC<IName> = ({
  text,
  defaults,
  values
}) => (
  <Trans
    i18nKey={text}
    defaults={defaults}
    values={values}
    components={{ italic: <i />, bold: <strong />, a: <a /> }}
  />
)