import styled, { css } from 'styled-components'
import { Icon } from 'components/styled'
import { HeaderImg } from 'components/header/types/HeaderImg'
import { Title4, Title5 } from 'components/typography'

import icons from 'components/theme/icons'

import { grayColor, infoColor, successColor, twitterColor } from 'components/theme/constants'
import images from 'components/theme/images'
import { Button, Grid, Typography } from 'components/core'
import { HeaderLinkIcon } from 'components/header/types/HeaderLinkIcon'
import { List } from 'components/list/List'
import { ISize } from 'utils/interface'
import { CustomLink } from 'components/button/CustomLink'
import shadows from 'components/theme/shadows'

const getNameSize = (size: ISize) => ({
  [ISize.S]: 0.7,
  [ISize.M]: 0.6,
  [ISize.L]: 0.5,
})[size]

export const HeaderTitle = styled.span`
  width: 100%;
  text-align: center;
`
export const HeaderAvatar = styled(HeaderImg).attrs(props => ({
  value: props.avatar || images.avatar,
  cover: !!props.avatar
}))`
  ${({ cover }) => cover || css`
    background-size: auto;
    background-repeat: no-repeat;
  `}
`
export const CloseIcon = styled(Icon).attrs(() => ({
  icon: icons.close,
  size: '1x'
}))`
  font-size: 1.25rem;
`
export const MenuIcon = styled(Icon).attrs(() => ({
  icon: icons.menu,
  size: '2x'
}))`
  margin-left: 1rem;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  `}
`

export const BackIcon = styled(HeaderLinkIcon).attrs(() => ({
  value: icons.back,
  size: '1x'
}))`
  color: inherit;
  margin-left: 1rem;
`
export const SearchIcon = styled(HeaderLinkIcon).attrs(() => ({
  value: icons.search,
  size: '1x'
}))`
  color: inherit;
  margin: 0.5rem;
  margin-right: 0;
  zoom: 1.25;
  border: 1px solid ${grayColor[22]};
  padding: 0.1rem 0.25rem;
  border-radius: 0.25rem;
  color: ${grayColor[0]};
  background-color: ${grayColor[2]};
`
export const MainGridStyled = styled(Grid)`
  width: 100%;
`
export const EllipsisParentStyled = styled.div`
  overflow: hidden;
`
export const EllipsisStyled = styled.span`
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ListHorizontal = styled(List).attrs(() => ({
  variant: 'horizontal'
}))`
  margin-bottom: 2rem;
`
export const ListVertical = styled(List).attrs(() => ({
  variant: 'vertical'
}))`
  margin-bottom: 2rem;
`
export const NameStyled = styled(EllipsisStyled)`
  display: block;
  text-align: left;
  font-size: ${props => getNameSize(props.size)}rem;
  min-width: 0;
  ${({ big }) => big
    ? css`
    font-weight: bold;
  `
    : css`
    margin-top: .8rem;
  `}
  
`
export const TextStyled = styled(Typography).attrs((props) => ({
  variant: 'caption',
  small: props.$smallText ? 1 : 0
}))`
  font-size: 0.7rem;
  ${({ small }) =>
    small &&
    css`
      font-size: 0.6rem;
      `
  }
`

export const LinkSideStyled = styled(CustomLink)`
  color: ${infoColor[0]};
  font-size: 0.75rem;
  text-transform: uppercase;
  text-decoration: none;
  & span {
    padding-right: 0.3rem;
  }
`
const GenericButton = styled(Button)`
  text-transform: none; 
`
export const PrimaryButton = styled(GenericButton).attrs(() => ({
  variant: 'contained',
  color: 'primary'
}))`
`
export const PrimaryEmptyButton = styled(GenericButton).attrs(() => ({
  variant: 'outlined',
  color: 'primary'
}))`
`
export const SecondaryButton = styled(GenericButton).attrs(() => ({
  variant: 'contained',
  color: 'secondary'
}))`
`
export const SecondaryEmptyButton = styled(GenericButton).attrs(() => ({
  variant: 'outlined',
  color: 'secondary'
}))`
`
export const TransparentButton = styled(GenericButton).attrs(() => ({
  color: 'primary'
}))`
`
export const TitleStyled = styled(Title5).attrs(() => ({
  weight: 'bold',
}))`
  margin: 0 0 0.5rem 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`
export const TitleCenteredStyled = styled(Title4).attrs(() => ({
  weight: 'bold',
}))`
  margin: 0;
  text-align: center;
`
export const PercentStyled = styled.div`
  border-radius: 0 0px 5px 5px;
  background: ${successColor[0]};
  opacity: 0.5;
  ${({ value }) =>
    css`
      width: ${value * 100}%;
    `
  }
  height: 1rem;
  position: absolute;
`
export const PercentFullStyled = styled(PercentStyled).attrs(() => ({
  value: 1,
}))`
  background: ${successColor[5]};
`
export const PercentWrapperStyled = styled.div`
  position: relative;
  border-radius: 5px;
  box-shadow: ${shadows.innerShadow};
  width: 100%;
  height: 1rem;

  & span {
    color: ${grayColor[4]};
    text-align: center;
    width: 100%;
    position: absolute;
  }
`