// import { isBrowser } from 'utils/helpers';
import environment from './base';

// const base =  `${isBrowser() ? window.location.protocol : 'https:'}//acabaramos.com/`;
  const base = 'https:///acabaramos.com/';
const baseApi = `${base}api.php`;
const baseAuth = `${base}auth.php`;

const env = environment(base, baseApi, baseAuth);

const productionEnv = {
  ...env,
  GATSBY_API_URL: base + 'graphql.php',
  GATSBY_MEDIA_URL: base + 'img/',
  GATSBY_MEDIA_ASSET: base + 'assets/img/',
  isProduction: true,
  isDevelopment: false,
};

export default productionEnv;
