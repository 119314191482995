import styled from 'styled-components'
import { CustomLink } from 'components/button/CustomLink'
import { Text1 } from 'components/typography'

export const CookiesWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 21rem;
  text-align: justify;
  margin: 0 auto;
`
export const CustomLinkStyled = styled(CustomLink) `
  font-size: 0.7rem;
  color: inherit;
  & a {
    text-decoration: underline;
  }
`
export const Text1Styled = styled(Text1) `
  font-size: 0.7rem;
  padding-bottom: 0.5rem;
`