import React, { FC, memo } from 'react'
import { IChildren } from 'utils/interface'
import { Drawer } from 'components/drawer/Drawer'
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { useModalStore } from 'use/modal/useModal';

interface IProps extends IChildren {
  id: string;
  anchor: 'top' | 'bottom';
  onClose?: () => void;
}
const theme = createTheme({
  palette: {
    type: "dark"
  }
});

const DrawerComposeMemo: FC<IProps> = ({
  id,
  anchor,
  onClose,
  children
}) => {

  const { isOpen, close } = useModalStore(id)
  const handleClose = () => {
    close()
    onClose && onClose()
  }
  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor={anchor}
        open={isOpen}
        onClose={handleClose}>
        {children}
      </Drawer>
    </ThemeProvider>
  )
}

export const DrawerCompose = memo(DrawerComposeMemo)