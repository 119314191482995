// custom typefaces
import "typeface-roboto"
import "typeface-roboto-slab"
import * as React from 'react';
import { GatsbyTopLayout } from './gatsby-top-layout';

// const initialState = window.__APOLLO_STATE__
// console.log('initialState', initialState);

export const wrapRootElement = ({ element }) => {
  return <GatsbyTopLayout>{element}</GatsbyTopLayout>
}