import env from "setup/environment";

export interface ICollectionModel {
  code: number; // CodigoC
  name: string; // Nombre
  description: string; // Descripcion
  year: number;
  subject: string;
  key: string;
}

export default class CollectionModel {

/*
  Coleccion
  {
    "CodigoC":1,
    "CodigoEd":14,
    "Nombre":"Adrenalyn XL Liga Santander 2016-17",
    "Descripcion":"Cambia cromos de la colecci\u00f3n de la liga espa\u00f1ola de f\u00fatbol en la temporada 2016\/2017. La primera edici\u00f3n de la colecci\u00f3n consta de 471 cartas, ampliada posteriormente con los fichajes del mercado de invierno y los entrenadores. Incluye dos nuevas series, Super Draft y Super H\u00e9roes y desaparece Diamantes.",
    "Tipo":"Cards",
    "Year":2017,
    "Mes":1,
    "Idioma":"Espa\u00f1ol",
    "Tema":"F\u00fatbol",
    "Top":3,
    "PClave":"AdrenalynXL ES",
    "Pagina":0,
    "Visual":0
  }
*/
  static getImage = (code: number) => `${env.GATSBY_MEDIA_URL}col${code}.jpg`

  static parseFlags = (settings: string = ':') => {
    const data = settings.split(':')
    /* 
    var globalData = (data[0] || userInfo.Caracteristicas ||
      CONTROL.wizard.setup.data.defaultGlobal).split('')
      defaultGlobal: '3NNN',
            defaultCol: 'NNN'
      */
    const defaultCol = 'NNN'
    const colData = data[1] || defaultCol
  
    return {
      /*
      tiempo: parseInt(globalData[0], 10),
      perfectos: globalData[1] === 'S',
      mismacol: globalData[2] === 'S',
      amigos: globalData[3] === 'S',
    */
      fav: colData[0] === 'S',
      sleep: colData[1] === 'S',
      deleted: colData[2] === 'S'
    }
  }

  static filterSubject = (subject: string) => subject.split(':')[0]
}