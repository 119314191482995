import { createSlice } from '@reduxjs/toolkit'
import * as set from './set'
import * as get from './get'

const { actions, reducer } = createSlice({
  name: 'filter',
  initialState: {
    filtered: {},
    sorted: {}
  },
  reducers: set
})

export {
  actions,
  get,
}
export default reducer
