import React from 'react';
import { ReactElement } from 'react';

import ReactGA from 'react-ga'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { isBrowser } from 'utils/helpers';

export function withGoogleTag(element: ReactElement) {
  const gtmParams = {
    id: 'GTM-NRWJFKJ'
    //dataLayerName: 'customDataLayerName'
  }
  
  if (isBrowser()) {
    const pageView = window.location.pathname + window.location.search
    ReactGA.initialize('UA-122254422-1')
    ReactGA.pageview(pageView)
    // console.log('REACT GA', pageView);
  }
  
  return (
    <GTMProvider state={gtmParams}>
      {element}
    </GTMProvider>
  )
}