import React, { FC } from 'react'
import useI18n from 'use/i18n/useI18n'
import { ButtonWrapper } from 'components/button/styled'
import { IResultModel } from 'model/Confirm.model'
import { useModalResult } from 'use/modal/useModal'
import { Modal } from 'components/modal/Modal'
import { AcceptButtonStyled, CancelButtonStyled } from './result/styled'

const labelIds = {
  accept: 'popup.confirm.accept',
  cancel: 'popup.confirm.cancel'
}

export const PopupResultCompose: FC = () => {
  const { isOpen, close, data }: {
    isOpen: boolean,
    close: () => void,
    data: IResultModel<unknown>
  } = useModalResult()
  const { tm } = useI18n()
  const defaultLabels = tm(labelIds)
  const { 
    accept,
    cancel,
    disabled,
    element: Element,
    elementProps,
    labels = defaultLabels,
   } = data || {}

  return isOpen ? 
    <Modal open={isOpen} handleClose={close}>
      { isOpen && <>
        <Element {...elementProps} />
        <ButtonWrapper>
          <CancelButtonStyled onClick={cancel}>{labels.cancel}</CancelButtonStyled>
          <AcceptButtonStyled onClick={accept} disabled={disabled}>{labels.accept}</AcceptButtonStyled>
        </ButtonWrapper>
      </>}
    </Modal> : <></>
}
