import { IconDefinition } from 'components/theme/icons';
import { ICollectionModel } from "model/Collection.model";
import { IElementModel } from "model/Element.model";
import { IUserCollectionModel } from "model/UserCollection.model";
import { IUserElementModel, IUserElementModelEx } from 'model/UserElement.model';
import React, { SyntheticEvent } from "react";
import SortTypeEnum from "use/filter/store/model/SortTypeEnum";

export enum ISize {
  S = 'S',
  M = 'M',
  L = 'L'
}
export interface IAllStringProps {
  [key: string]: string;
}
export interface IButton {
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
}
export interface ISimpleButtonProps {
  label: string;
  onClick: () => void;
  item?: React.FC;
}
export interface IClasses {
  classes?: IAllStringProps;
}
export interface IClassName {
  className?: string;
}
export interface IChildren {
  children: React.ReactNode;
}
export interface IChildrenOpt {
  children?: React.ReactNode;
}
export interface IClick {
  onClick: (_?: SyntheticEvent, type?: string) => void;
}

export interface ICollectionsPage {
  collectionsPage: {
    total?: number;
    page?: number;
    data?: ICollectionModel[];
  };
}
export interface IUserCollectionsPage {
  userCollectionsPage: {
    total?: number;
    page?: number;
    data?: IUserCollectionModel[];
  };
}
export interface IExchangeCol {
  error?: boolean;
  selected: number;
}
export interface IAutorize {
  authorized: boolean;
  loading: boolean;
  error?: string;
  currentUser?: any;
}
export interface IRegisterProps {
  email: string;
  password: string;
  username: string;
}
export interface IResetProps {
  password: string;
}
export interface IResetHiddenProps {
  selector: string;
  token: string;
}
export interface ILoginProps {
  email: string;
  password: string;
}
export interface IRecoverProps {
  email: string;
}
export interface ICropText {
  text: string;
  size: number;
}
export type TElement = string
export interface IElementMap {
  [key: string]: IElementModel;
}

export interface IMappedElement {
  mappedElements: { [key: string]: IElementModel };
  mappedUserElements: { [key: string]: IUserElementModelEx };
}
export interface IMappedElementMap {
  [key: string]: IMappedElement;
}
export interface IElementDoubleMap {
  [key: string]: IElementMap;
}
export interface IMappedElementDoubleMap {
  [key: string]: IMappedElementMap;
}
export interface IIconButton {
  icon: IconDefinition;
  label: string;
  onClick?: () => void;
}
export interface IMenuLink {
  icon: string | IconDefinition;
  text: string;
  href?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  variant?: string;
}
export interface IMenuSep {
  sep: boolean;
  href?: string;
}
export interface IMenuProps {
  index: number;
  links?: (IMenuLink | IMenuSep)[];
  onChange?: (e:number) => void;
}
export interface IReadonly {
  readonly: boolean;
}
export interface ISortModel {
  name: string;
  type: SortTypeEnum;
  asc?: boolean;
  fn?: (a: any, b:any) => number;
}
export interface ISortModelMap {
  [key: string]: ISortModel[]
}
export interface IStringArray {
  [key: string]: string[];
}

export type ITranslate = (key: string) => string