import objectPath from 'object-path'
import { isBrowser } from 'utils/helpers'

const browser = isBrowser()

export default function useStorage() {
  return {
    getItem,
    setItem,
    clearItem,
    getFlag,
    setFlag,
  }
}

function getItem (name: string): any {
  const item = browser && window.localStorage.getItem(name)
  return item && JSON.parse(item)
}
function setItem (name: string, value: any) {
  browser && window.localStorage.setItem(name, value && JSON.stringify(value))
}
function clearItem(name: string) {
  browser && window.localStorage.removeItem(name)
}
function getFlag<T>(flagName: string): T | boolean {
  const flags = getItem('flags') || {}
  return objectPath.get(flags, flagName)
}
function setFlag<T>(flagName: string, value:T | boolean) {
  const flags = getItem('flags') || {}
  objectPath.set(flags, flagName, value)

  setItem('flags', flags)
}