import { createSlice } from '@reduxjs/toolkit'
import * as set from './set'
import * as get from './get'

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
  },
  reducers: set
})

export {
  actions as authActions,
  get as authGet,
}
export default reducer
