import React, { FC } from 'react'
import { flags, routes } from 'setup/constants'

import useStorage from 'use/storage/useStorage'
import { useModalStore } from 'use/modal/useModal'

import { DrawerCompose } from 'compose/layout/DrawerCompose'

import { PrimaryButton } from 'components/common/common.styled'
import { CookiesWrapper, CustomLinkStyled, Text1Styled } from './cookies/styled'
import useI18n from 'use/i18n/useI18n'
import { useEffect } from 'react'


const labelIds = {
  button: 'popup.cookies.button',
  description: 'popup.cookies.description',
  review: 'popup.cookies.review',
  reviewLink: 'popup.cookies.reviewLink'
}
export const PopupCookiesCompose: FC = () => {
  const flag = flags.popupCookies
  const { getFlag, setFlag } = useStorage()
  const firstTime = !getFlag(flag)
  const { open, close } = useModalStore(flag)
  const labels = useI18n().tm(labelIds)

  const closeMessage = () => {
    setFlag(flag, true)
    close()
  }

  useEffect(() => {
    if (firstTime) {
      setTimeout(open)
    }
  }, [])

  return (
    <DrawerCompose
      anchor="bottom"
      id={flag}>
      <CookiesWrapper>
        <Text1Styled>{ labels.description }</Text1Styled>
        <Text1Styled>{ labels.review } <CustomLinkStyled to={routes.cookiePolicy}>{ labels.reviewLink }</CustomLinkStyled></Text1Styled>
        <PrimaryButton onClick={closeMessage}>{ labels.button }</PrimaryButton>
      </CookiesWrapper>
    </DrawerCompose>
  )
}