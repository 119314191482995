import { AppBar } from 'components/core';
import styled from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import { dangerColor, grayColor, infoColor, primaryColor, roseColor, successColor, warningColor, whiteColor } from './theme/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colorBackground, colorText } from './common/styled';

const getColor = (color:string) => ({
  primary: primaryColor[0],
  warning: warningColor[0],
  danger: dangerColor[0],
  success: successColor[0],
  info: infoColor[0],
  rose: roseColor[0],
  gray: grayColor[7]
}[color])

const iconSizes = {
  sm: 0.625,
  md: 0.75,
  lg: 1,
  xl: 1.375
}

export const Icon = styled(FontAwesomeIcon)`
  overflow: unset;
`

export const Badge = styled.span`
  margin-right: 3px;
  border-radius: 12px;
  padding: 5px 8px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  display: inline-block;
  color: ${whiteColor};
  ${props => colorBackground(props)}
`
export const Paragraph = styled.p`
  ${props => colorText(props)}
`
export const AppBarStyled = styled(AppBar)<{ theme: Theme }>`
  ${({ theme }) => `
    z-index: ${theme.zIndex.drawer + 1};
    transition: ${theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })} !important;
  `}
`
export const SROnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
`
export const HiddenIcon = styled(Icon)`
  visibility: hidden;
`