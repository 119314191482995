import FilterModel from 'use/filter/store/model/FilterModel'

interface IPayloadObject {
  payload: any;
}

export const setFiltered = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    filtered: {
      ...state.filtered,
      ...{ [payload.id]: payload.filter }
    }
  };
};

export const setSorted = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    sorted: {
      ...state.sorted,
      ...{ [payload.id]: payload.sort }
    }
  };
};

export const update = (state: any, { payload }: IPayloadObject) => {
  const { id, name, filter } = payload
  return {
    ...state,
    filtered: {
      ...state.filtered,
      ...{ [id]: FilterModel.update(state.filtered[id], name, filter) }
    }
  };
};