import { CircularProgress } from '@material-ui/core'
import { BackdropStyled } from 'compose/layout/styled';
import React, { FC } from 'react'

interface IProps {
  open: boolean;
}
export const Spinner: FC<IProps> = ({
  open
}) => {
 
  return (
    <BackdropStyled open={open}>
      <CircularProgress color='primary' />
    </BackdropStyled>
  )
}