interface IPayloadObject {
  payload: any;
}


export const openModal = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    ...{ [payload.id]: { open: true, data: payload.data, refetch: payload.refetch }}
  };
};

export const closeModal = (state: any, { payload }: IPayloadObject) => {
  return {
    ...state,
    ...{ [payload.id]: undefined }
  };
};

export const clearModals = (state: any) => {
  return {
  };
};