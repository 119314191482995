import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import ReactGA from 'react-ga'

const useTag = () => {
  // const sendDataToGTM = useGTMDispatch()
  const sendDataToGTM = (tag) => console.log('TAG', tag)
  return {
    tag: sendDataToGTM
  }
}
export const pageView = (path) => { 
  ReactGA.pageview(path)
}
export default useTag