import styled, { css } from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import { Dialog } from 'components/core';

export const DialogStyled = styled(Dialog)`
  display: block;
  width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: 80%;
      margin: 0 auto;
      max-width: 800px;
    }
  `}
`;
export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `}
`
export const ModalWrapper = styled.div<{ theme: Theme }>`
  ${({theme}) => css`
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[5]};
    border-radius: 8px;
    padding: ${theme.spacing(3)}px;
    &:focus {
      outline: none;
    }
  `}
`;

export const WrapperFull = styled.div<{ theme: Theme }>`
  ${({theme}) => css`
  padding: ${theme.spacing(2)}px;
    &:focus {
      outline: none;
    }
  `}
`;

