import React, { FC, SyntheticEvent } from 'react';

import { Alert as AlertLab, Color } from '@material-ui/lab';
import { Snackbar } from 'components/core';
import { SnackbarOrigin } from '@material-ui/core';
import { ISimpleButtonProps } from 'utils/interface';
import { ButtonsWrapper, SnackbarStyled } from 'components/message/styled';
import { ButtonWrapper } from 'components/button/styled';
import { RawButtonWithIcon } from 'components/button/RawButtonWithIcon';
import { TransText } from 'components/common/TransText';


interface IProps {
  openAlert?: boolean;
  text?: string;
  severity: Color;
  handleClose: () => void;
  autoHideDuration?: number;
  anchor?: SnackbarOrigin;
  buttons?: ISimpleButtonProps[];
}

export const Alert: FC<IProps> = ({
  openAlert,
  buttons,
  text,
  severity,
  handleClose,
  autoHideDuration = 5000,
  anchor = { vertical: 'top', horizontal: 'center' }}) => { 
  const onClose = (_?: SyntheticEvent, reason?: string) => {
    if (reason !== 'clickaway') {
      handleClose()
    }
  }
  return (
    <SnackbarStyled
      open={openAlert}
      autoHideDuration={autoHideDuration}
      key="topcenter"
      anchorOrigin={anchor}
    >
      <AlertLab onClose={onClose} variant="filled" severity={severity}>
        <TransText text={text} />
        {buttons && buttons.length &&
          (
            <ButtonWrapper>
              {buttons.map(({ item, label, onClick }) => (
                <RawButtonWithIcon onClick={onClick} item={item}>
                  {label}
                </RawButtonWithIcon>
              ))}
            </ButtonWrapper>
          )
        }
      </AlertLab>
    </SnackbarStyled>
  );
}