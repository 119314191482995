import React from 'react';
import { ReactElement } from 'react';

import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components';
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'



export function withTheme(element: ReactElement) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 400,
        sm: 600,
        md: 768,
        lg: 1000,
        xl: 1280,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 45,
      }
    },
    typography: {
      fontFamily: 'Roboto',
      h1: {
        fontSize: '3rem',
      },
      h2: {
        fontSize: '2.5rem',
      },
      h3: {
        fontSize: '2rem',
      },
      h4: {
        fontSize: '1.5rem',
      },
      h5: {
        fontSize: '1.2rem',
      },
      h6: {
        fontSize: '1rem',
      },
    },
  });
  // library.add(fab, fas)

  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          {element}
        </ThemeProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
}