import React, { FC } from 'react'
import { GatsbyLinkProps} from 'gatsby'

import { IChildren } from 'utils/interface'
import { pageView } from 'use/tag/useTag'
import { AStyled, LinkStyled } from './styled'


export interface IProps extends IChildren {
  onClick?: (e) => void;
  to?: string;
  out?: string;
  className?: string;
  activeClassName?: string;
}


export const CustomLink: FC<IProps> = ({ onClick, out, to, ...props}) => {
  const link = to || out
  const customOnClick = e => {
    pageView(link)
    onClick && onClick(e)
  }
  return <>
    {to && <LinkStyled onClick={customOnClick} to={to}
      className={props.className}
      activeClassName={props.activeClassName}
      children={props.children} />}
    {out && <AStyled onClick={customOnClick} href={out}
      className={props.className}
      children={props.children} />}
    </>
}
