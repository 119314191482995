import axios, { AxiosError, AxiosResponse, Method } from 'axios'

export interface IRequest {
  url: string;
  headers?: any;
  method: Method;
  timeout?: number;
  data?: any;
}
export interface IFetchError {
  errorMessage: string
}

export default async function(req: IRequest) 
{
  if (!Boolean(req.url)) {
    console.error(`Received ${req.url} which is invalid for a endpoint url`);
  }
  
  try {
    const [axiosResponse] = await Promise.all([axios({
      method: req.method,
      url: req.url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...req.headers,
      },
      data: req.data
    //}), delay()]);
    })]);

    const { status, data, request } = axiosResponse;

    return data;

  } catch (error) {

    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      const { status, statusText, data } = error.response;
      const errors = data.hasOwnProperty('errors') ? [statusText, ...data.errors] : [statusText];

      console.error('ERRORS', errors, status, statusText, data)
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      const { status, statusText, responseURL } = error.request;
      console.error('ERROR RESPONSE', error.request)
    }

    return {
      errorMessage: 'server.error'
    }
  }
}


function delay(duration = 250) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}