import styled from 'styled-components'
import { RawButtonWithIcon } from 'components/button/RawButtonWithIcon'
import { PrimaryButton, PrimaryEmptyButton } from 'components/common/common.styled'

export const AcceptButtonStyled = styled(RawButtonWithIcon).attrs(
  { item: PrimaryButton }
)`
`
export const CancelButtonStyled = styled(RawButtonWithIcon).attrs(
  { item: PrimaryEmptyButton }
)`
`

