import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import LanguageEnum from 'use/i18n/model/LanguageEnum'
import moment from 'moment'
import 'moment/locale/es';
import ES_es from './languages/ES_es.json'

const lng = LanguageEnum.SPAIN
const config = {
  debug: false,
  initImmediate: false,
  returnNull: true,
  returnEmptyString: true,
  skipInterpolation: true,
  preload: [lng],
  lng: lng,
  resources: {
    ES_es: { translation: ES_es }
  }
}
const useI18n = () => {
  const { t } = useTranslation()
  return {
    t,
    tm: i18nObj => {
      const result = {}
      Object.keys(i18nObj).forEach(key => {
        result[key] = t(i18nObj[key])
      })
      return result
    }
  }
}

export const initI18n = () => {
  i18n
    .use(initReactI18next)
    .init(config)

  moment().locale('es')
}
export default useI18n