import React from 'react'
import {
  AppBar as MAppBar,
  Avatar as MAvatar,
  Badge as MBadge,
  Button as MButton,
  Card as MCard,
  Checkbox as MCheckbox,
  Chip as MChip,
  Container as MContainer,
  Drawer as MDrawer,
  Dialog as MDialog,
  Fab as MFab,
  FormControlLabel as MFormControlLabel,
  Grid as MGrid,
  IconButton as MIconButton,
  InputAdornment as MInputAdornment,
  List as MList,
  ListItem as MListItem,
  ListItemIcon as MListItemIcon,
  ListItemText as MListItemText,
  Paper as MPaper,
  Radio as MRadio,
  RadioGroup as MRadioGroup,
  Snackbar as MSnackbar,
  Step as MStep,
  Stepper as MStepper,
  StepContent as MStepContent,
  StepLabel as MStepLabel,
  Switch as MSwitch,
  Tab as MTab,
  Tabs as MTabs,
  TableBody as MTableBody,
  TableCell as MTableCell,
  TableContainer as MTableContainer,
  TableHead as MTableHead,
  TablePagination as MTablePagination,
  TableRow as MTableRow,
  TableSortLabel as MTableSortLabel,
  TextField as MTextField,
  Toolbar as MToolbar,
  Tooltip as MTooltip,
  Typography as MTypography,
  StepIconProps
} from '@material-ui/core';

import {
  Autocomplete as MAutocomplete,
} from '@material-ui/lab'

let AppBar = MAppBar
let Autocomplete = MAutocomplete
let Avatar = MAvatar
let Badge = MBadge
let Button = MButton
let Card = MCard
let Checkbox = MCheckbox
let Chip = MChip
let Container = MContainer
let Drawer = MDrawer
let Dialog = MDialog
let Fab = MFab
let FormControlLabel = MFormControlLabel
let Grid = MGrid
let IconButton = MIconButton
let InputAdornment = MInputAdornment
let List = MList
let ListItem = MListItem
let ListItemIcon = MListItemIcon
let ListItemText = MListItemText
let Paper = MPaper
let Radio = MRadio
let RadioGroup = MRadioGroup
let Snackbar = MSnackbar
let Step = MStep
let Stepper = MStepper
let StepContent = MStepContent
let StepLabel = MStepLabel
let Switch = MSwitch
let Tab = MTab
let Tabs = MTabs
let TableBody = MTableBody
let TableCell = MTableCell
let TableContainer = MTableContainer
let TableHead = MTableHead
let TablePagination = MTablePagination
let TableRow = MTableRow
let TableSortLabel = MTableSortLabel
let TextField = MTextField
let Typography = MTypography
let Toolbar = MToolbar
let Tooltip = MTooltip


if (typeof window === "undefined") {
  AppBar = ({children}) => (<header>{children}</header>)
  Autocomplete = (props) => (<div></div>)
  Avatar = ({src}) => (<img src={src} />)
  Badge = ({children}) => (<span>{children}</span>) 
  Button = ({children}) => (<button>{children}</button>)
  Card = ({children}) => (<div>{children}</div>) 
  Checkbox = ({children}) => (<input>{children}</input>)
  Chip = ({children}) => (<>{children}</>)
  Container = ({children}) => (<div>{children}</div>)
  Drawer = ({children}) => (<>{children}</>)
  Dialog = ({children}) => (<>{children}</>)
  Fab = ({children}) => (<button>{children}</button>)
  FormControlLabel = ({children}) => (<>{children}</>)
  Grid = ({children}) => (<>{children}</>)
  IconButton = ({children}) => (<button>{children}</button>)
  InputAdornment = ({children}) => (<div>{children}</div>)
  List = ({children}) => (<ul>{children}</ul>)
  ListItem  = ({children}) => (<li>{children}</li>)
  ListItemIcon = () => (<i></i>)
  ListItemText = ({children}) => (<>{children}</>)
  Paper = ({children}) => (<>{children}</>)
  RadioGroup = ({children}) => (<div>{children}</div>)
  Radio = ({children}) => (<input>{children}</input>)
  Snackbar = ({children}) => (<div>{children}</div>)
  Step = ({children}) => (<div>{children}</div>)
  Stepper = ({children}) => (<div>{children}</div>)
  StepContent = ({children}) => (<div>{children}</div>)
  StepLabel = ({children}) => (<>{children}</>)
  Switch = ({children}) => (<input />)
  Tab = ({children}) => (<div>{children}</div>)
  Tabs = ({children}) => (<button>{children}</button>)
  TableBody = ({children}) => (<ul>{children}</ul>)
  TableCell = ({children}) => (<ul>{children}</ul>)
  TableContainer = ({children}) => (<>{children}</>)
  TableHead = ({children}) => (<th>{children}</th>)
  TableRow = ({children}) => (<tr>{children}</tr>)
  TableSortLabel = ({children}) => (<>{children}</>)
  TablePagination = ({children}) => (<>{children}</>)
  TextField = ({}) => (<input />)
  Toolbar = ({children}) => (<div>{children}</div>)
  Tooltip = ({children}) => (<>{children}</>)
  Typography = ({children}) => (<p>{children}</p>)
}

export {
  AppBar,
  Autocomplete,
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  Drawer,
  Dialog,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Step,
  Stepper,
  StepContent,
  StepLabel,
  Switch,
  Tab,
  Tabs,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TextField,
  Toolbar,
  Tooltip,
  Typography
};
