import { IAllStringProps, ICropText, ISize } from 'utils/interface';
import objectPath from 'object-path'
import { hexToRgb } from 'components/theme/constants';

export function cropText({ text, size }: ICropText) {
  return (text.length > size) ? `${text.slice(0, size)} ...` : text;
}

export function priceToStr(price: number) {
  const re = /(\d)(?=(\d{3})+(?!\d))/g;
  const re1 = /(\d{1})/;
  const newPrice = String(price);
  return newPrice.replace(re, '$1 ').replace(re1, '$ $1')
}

export function priceToNum(price: string) {
  const re = /(\$)?( )/gi;
  return Number(price.replace(re, ''))
}

export function priceSumArr(items: any) {
  if (!items || items.length === 0) return '$ 0';
  const allSum = items.reduce((acc: any, item: string) => acc + item);
  return priceToStr(allSum);
}

export function isBrowser () { return typeof window !== 'undefined' }
export function isEmpty(obj) {
  if (!obj) return true
  let name
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false
    }
  }
  if (obj.constructor !== Object) {
    return false
  }
  return true
}

export function mapBy(list = [], pk) {
  return list.reduce((tot, item) => {
    tot[objectPath.get(item, pk)] = item
    return tot
  }, {})
}

export function mapToObjectList(ob = {}) {
  return Object.keys(ob).map(key => ob[key])
}

export function mapArrayBy(list = [], pk) {
  return list.reduce((tot, item) => {
    const key = objectPath.get(item, pk)
    if (!tot[key]) {
      tot[key] = []
    }
    tot[key].push(item)

    return tot
  }, {})
}
export function doubleMap (list = [], pk1: string, pk2: string) {
  if (list) {
    const map = {};
    list.forEach(function (item) {
      const v1 = item[pk1];
      const v2 = item[pk2];
      if (!map[v1]) {
        map[v1] = {};
      }
      map[v1][v2] = item;
    });
    return map;
  }
}
export function randomColor (str: string, shade = -0.2) {
  if (!str) return ''
  const hash = hashCode(str)
  const hex = Math.floor(hash * 0xFFFFFF).toString(16).slice(-6)
  return RGB_Linear_Shade(shade, `rgb(${hexToRgb(hex).split(' ').join('')})`)
}
export function randomDarkColor (str: string) {
  return randomColor(str, -0.4)
}

const RGB_Linear_Shade=(p,c)=>{
  var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=p<0,t=P?0:255*p,P=P?1+p:1-p;
  return"rgb"+(d?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+t)+","+r(i(b)*P+t)+","+r(i(c)*P+t)+(d?","+d:")");
}
const hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)

export function range(i: number) {
  return i <= 0 ? [] : range(i-1).concat(i-1)
}

export function getSize (str: string, mid: number, long: number) {
  if (!str) return ISize.M
  if (str.length > long) {
    return ISize.L
  }
  if (str.length > mid) {
    return ISize.M
  }
  return ISize.S
}

export function queryParams(location: IAllStringProps): IAllStringProps {
  return (location.search || '').replace('?', '').split('&').reduce((tot, acc) => {
    const [ key, value ] = acc.split('=')
    tot[key] = value
    return tot
  }, {})
}

export function replaceHistoryState(payload: any, href: string): void {
  isBrowser() && window.history.replaceState(payload, href)
}
export function loadHistoryState(): any {
  return isBrowser() && window.history.state
}