import React, { FC } from 'react'
import images from 'components/theme/images'
import { IChildrenOpt } from 'utils/interface'

interface IProps extends IChildrenOpt {
  label: string;
}
export const Splash: FC<IProps> = ({
  children,
  label
}) => {
  return (

    <span className='splash'>
      <img src={images.logo} />
      <p>{ label }</p>
      <div style={{display: 'none'}}>{children}</div>
    </span>
  )
}
