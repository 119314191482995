import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faAt } from "@fortawesome/free-solid-svg-icons/faAt";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBed } from "@fortawesome/free-solid-svg-icons/faBed";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { faCameraRetro } from "@fortawesome/free-solid-svg-icons/faCameraRetro";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faClone } from "@fortawesome/free-solid-svg-icons/faClone";
import { faCoffee } from "@fortawesome/free-solid-svg-icons/faCoffee";
import { faColumns } from "@fortawesome/free-solid-svg-icons/faColumns";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons/faDotCircle";
import { faDragon } from "@fortawesome/free-solid-svg-icons/faDragon";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faFilm } from "@fortawesome/free-solid-svg-icons/faFilm";
import { faFutbol } from "@fortawesome/free-solid-svg-icons/faFutbol";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons/faGlobeAmericas";
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faHourglassEnd } from "@fortawesome/free-solid-svg-icons/faHourglassEnd";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons/faMapMarker";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faRandom } from "@fortawesome/free-solid-svg-icons/faRandom";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faSort } from "@fortawesome/free-solid-svg-icons/faSort";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faThLarge } from "@fortawesome/free-solid-svg-icons/faThLarge";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faUndo } from "@fortawesome/free-solid-svg-icons/faUndo";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons/faShareAlt";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


export default {
  add: faPlus,
  allCollections: faClone,
  arrowRight: faArrowRight,
  address: faMapMarker,
  back: faArrowLeft,
  blog: faBook,
  categories: faColumns,
  changeFinish: faCoffee,
  changeStart: faCheckCircle,
  changeInit: faPlusCircle,
  changeUpdate: faHandshake,
  chat: faPaperPlane,
  check: faCheck,
  checkEmpty: faDotCircle,
  cinema: faFilm,
  clear: faTrashAlt,
  close: faTimes,
  comic: faDragon,
  community: faGlobe,
  country: faGlobeAmericas,
  delete: faTimesCircle,
  edit: faPencilAlt,
  email: faAt,
  exchange: faExchangeAlt,
  fav: faStar,
  facebook: faFacebookF,
  filterApply: faChevronUp,
  filterClear: faUndo,
  help: faQuestionCircle,
  historical: faHourglassEnd,
  index: faInfoCircle,
  info: faInfoCircle,
  like: faThumbsUp,
  lock: faLock,
  logout: faSignOutAlt,
  login: faSignInAlt,
  menu: faBars,
  numSep: faCaretRight,
  profile: faUser,
  publicSearch: faSearch,
  random: faRandom,
  right: faCaretRight,
  search: faSearch,
  share: faShareAlt,
  sleep: faBed,
  soccer: faFutbol,
  soccerClassic: faCameraRetro,
  sort: faSort,
  twitter: faTwitter,
  unfold: faChevronUp,
  unlike: faCheck,
  userChanges: faHandshake,
  userCollections: faColumns,
  userDashboard: faHome,
  userSearch: faSearch,
  viewBig: faTh,
  viewSmall: faThLarge,
  whatsapp: faWhatsapp,
}

export type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
