import { IResultChangeProps } from 'compose/popup/change/ResultChangeCompose'
import { IConfirmModel, IResultModel } from 'model/Confirm.model'
import { ISearchCollectionModel } from 'model/SearchCollection.model'
import { IUserInfoExModel } from 'model/UserInfoEx.model'
import { useDispatch, useSelector } from 'react-redux'
import { modals } from 'setup/constants'
import { IRefetch } from 'use/fetch/interface'
import { IPopupChangeData, IPopupCollectionData, IPopupCollectionEditData, IPopupCollectionShareData } from 'use/popup/impl/interface'
import { actions as modalActions, get as modalGet } from './store'

const { isOpen, getData, getRefetch } = modalGet
const { openModal, closeModal, clearModals } = modalActions

export const useModalStore = function<T> (id: string) {
  const dispatch = useDispatch()
  const open = (data?: T, refetchProps?: IRefetch) => {
    dispatch(closeModal({ id: modals.alert }))
    dispatch(openModal({ ...{ id, data }, ...refetchProps }))
  }
  const close = () => {
    dispatch(closeModal({ id }))
  }
  const clear = () => {
    dispatch(clearModals())
  }

  return {
    isOpen: useSelector(isOpen(id)),
    data: useSelector(getData(id)),
    open,
    close,
    clear,
    refetch: useSelector(getRefetch(id)),
  }
}
export const useModalAlert = () => useModalStore(modals.alert)
export const useModalAlertWelcome = () => useModalStore(modals.alertWelcome)
export const useModalAuth = () => useModalStore(modals.auth)
export const useModalCollection = () => useModalStore<IPopupCollectionData>(modals.collection)
export const useModalCollectionNew = () => useModalStore<ISearchCollectionModel>(modals.collection)
export const useModalCollectionEdit = () => useModalStore<IPopupCollectionEditData>(modals.collectionEdit)
export const useModalCollectionShare = () => useModalStore<IPopupCollectionShareData>(modals.collectionShare)
export const useModalConfirm = () => useModalStore<IConfirmModel>(modals.confirm)
export const useModalChange = () => useModalStore<IPopupChangeData>(modals.change)
export const useModalResult = () => useModalStore<IResultModel<unknown>>(modals.result)
export const useModalResultChange = () => useModalStore<IResultModel<IResultChangeProps>>(modals.result)
export const useModalUser = () => useModalStore<IUserInfoExModel>(modals.user)