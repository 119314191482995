import React, { FC, memo } from 'react'

import {
  ContentWrapperStyled,
  HeaderDrawerStyled,
  HeaderCloseWrapperStyled,
  LogoWrapperStyled,
  SidebarWrapperStyled,
  BackgroundStyled,
  LogoStyled,
  DrawerTitleStyled,
} from './styled'
import { IChildren } from 'utils/interface'
import { Icon } from 'components/styled'
import icons from 'components/theme/icons'
import { routes } from 'setup/constants'
import { AStyled } from 'components/button/styled'

interface IProps {
  open: boolean;
  onToggle: () => void;
  title: React.ReactNode;
}

const HeaderDrawerMemo: FC<IProps & IChildren> = ({
  open,
  onToggle,
  title,
  children
}) => {

  return (
    <HeaderDrawerStyled
      title={title}
      open={open}
      onClose={onToggle}>       
      <HeaderCloseWrapperStyled onClick={onToggle}>
        <Icon icon={icons.close} size={'sm'} />
      </HeaderCloseWrapperStyled>
      <ContentWrapperStyled>
        <AStyled href={routes.home}>
          <LogoWrapperStyled>
            <LogoStyled />
            <DrawerTitleStyled>
              { title }
            </DrawerTitleStyled>
          </LogoWrapperStyled>
        </AStyled>
        <SidebarWrapperStyled>
          { children }
        </SidebarWrapperStyled>
        <BackgroundStyled />
      </ContentWrapperStyled>
    </HeaderDrawerStyled>
  )
}

export const HeaderDrawer = memo(HeaderDrawerMemo)


