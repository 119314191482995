import React, { FC, memo, useState } from 'react'
import { IChildrenOpt } from 'utils/interface'
import { PopupAlertCompose } from 'compose/popup/PopupAlertCompose'
import { HeaderDrawer } from 'components/drawer/HeaderDrawer'
import { DrawerLinks } from 'components/drawer/links/DrawerLinks'
import { drawers } from 'setup/constants'
import useMenu from 'use/menu/useMenu'
import useLayout, { useLayoutStore } from 'use/layout/useLayout'
import { useEffect } from 'react'
import { PopupCookiesCompose } from 'compose/popup/PopupCookiesCompose'
import { PopupConfirmCompose } from 'compose/popup/PopupConfirmCompose'
import { useModalStore } from 'use/modal/useModal'
import { PopupResultCompose } from 'compose/popup/PopupResultCompose'
import { Splash } from 'components/layout/Splash'
import useI18n from 'use/i18n/useI18n'
import { SEO } from 'components/seo/Seo'
import { Spinner } from 'compose/layout/Spinner'

interface IProps extends IChildrenOpt {
}
const PageComposeMemo: FC<IProps> = ({
  children
}) => {
  const brand = useI18n().t('common.brand')
  const { initScroll } = useLayout()
  const { clear } = useModalStore(null)
  const { open, toggle, drawer } = useMenu(drawers.menu, '')

  const { index, links, title } = drawer

  useEffect(() => {
    initScroll()
    clear()
  }, [])
  
  const { 
    isFirstLoad,
    isSpinner,
    pageTitle,
    updateFirstLoad
  } = useLayoutStore()
  
  // const brand = 'Acabáramos'
  useEffect(() => {
    updateFirstLoad(true)
  }, [])

  return (
    <>
      <SEO title={pageTitle} />
      <Spinner open={isSpinner} />
      {isFirstLoad
        ?(<>
            <HeaderDrawer
              title={title}
              open={open}
              onToggle={toggle}>
              <DrawerLinks
                index={index}
                links={links}
                onChange={toggle}> </DrawerLinks>
            </HeaderDrawer>
            {children}
            <PopupAlertCompose />
            <PopupConfirmCompose />
            <PopupCookiesCompose />
            <PopupResultCompose />
          </>
        ):<Splash label={brand} />}
      </>
    )
}

export const PageCompose = memo(PageComposeMemo)